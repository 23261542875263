import { DownloadOutlined, SyncOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Button, Col, Row, Table, Tooltip } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useEffect } from "react";
import {
  formatNumber,
  formatNumber2SigFig,
} from "../../../../../core/utils/campaigns";
import SelectCampaigns from "./SelectCampaigns";
import { columnsObj } from "./utils";
import ColumnsDropdown from "./utils/ColumnsDropdown";
import prepareExport from "./utils/prepareExport";

const MainView = ({
  tableData,
  isLoading,
  currentPagination,
  setPagination,
  total,
  onExport,
  campaignDataOptions,
  dspDataOptions,
  selectedCIDs,
  setSelectedCIDs,
  selectedDSP,
  setSelectedDSP,
  activeColumns,
  setActiveColumns,
  exportTableData,
  loadingExport,
  handleSorterChange,
  refetch,
}) => {
  const history = useHistory();

  const handleButtonClick = record => {
    history.push(`/campaigns/details/${record.id}`);
  };

  const columnRender = (key, value, record) => {
    if (key === columnsObj.Actions.key) {
      return (
        <Button
          type="primary"
          shape="round"
          onClick={() => handleButtonClick(record)}
        >
          VIEW
        </Button>
      );
    }
    if (
      key === columnsObj.daysIntoFlight.key ||
      key === columnsObj.daysLeft.key ||
      key === columnsObj.expectedImpressions.key ||
      key === columnsObj.impressionsLeft.key ||
      key === columnsObj.dailyAvgImpressionsLeft.key ||
      key === columnsObj.actualImpressions.key ||
      key === columnsObj.impressionsYesterday.key
    ) {
      if (value) {
        return <Text>{formatNumber(value)}</Text>;
      }
      return <Text>0</Text>;
    }

    if (
      [
        columnsObj.pace.key,
        columnsObj.paceYesterday.key,
        columnsObj.paceTwoDaysAgo.key,
        columnsObj.paceThreeDaysAgo.key,
        columnsObj.paceFourDaysAgo.key,
        columnsObj.paceFiveDaysAgo.key,
        columnsObj.paceSixDaysAgo.key,
        columnsObj.paceSevenDaysAgo.key,
      ].includes(key)
    ) {
      if (value) {
        let type = "";
        switch (true) {
          case value >= 95:
            type = "success";
            break;
          case value >= 91 && value <= 95:
            type = "warning";
            break;
          case value >= 0 && value <= 90:
            type = "danger";
            break;
          default:
            break;
        }
        return <Text type={type}>{formatNumber2SigFig(value)}%</Text>;
      }
      return <Text type="danger">0%</Text>;
    }

    if (key === columnsObj.roas.key || key === columnsObj.ctr.key) {
      if (value) {
        return <Text>{formatNumber2SigFig(value)}%</Text>;
      }
      return <Text>0%</Text>;
    }

    // Tooltip for longer text characters
    return (
      <Tooltip title={value}>
        <Text>{value}</Text>
      </Tooltip>
    );
  };

  const columns = Object.entries(columnsObj)
    .map(c => c[1])
    .map(c => ({
      ...c,
      render: (value, record) => columnRender(c.key, value, record),
    }))
    .filter(c => {
      return (
        activeColumns.indexOf(c.key) >= 0 || c.key === columnsObj.Actions.key
      );
    });

  columns.push({
    key: "additional",
    width: "5%",
    title: () => {
      return (
        <ColumnsDropdown
          selected={activeColumns}
          setSelected={setActiveColumns}
        />
      );
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    if (currentPagination.pageSize !== pagination.pageSize) {
      setPagination({ ...pagination, current: 1 });
    } else {
      setPagination(pagination);
    }
    handleSorterChange(sorter.columnKey, sorter.order);
  };

  const onRefresh = () => {
    refetch();
  };

  useEffect(() => {
    if (exportTableData) {
      prepareExport(exportTableData);
    }
  }, [exportTableData]);

  return (
    <React.Fragment>
      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        <Col xs={24} md={10} xl={7}>
          <SelectCampaigns
            campaignDataOptions={campaignDataOptions}
            selectedCIDs={selectedCIDs}
            setSelectedCIDs={setSelectedCIDs}
          />
        </Col>
        <Col xs={24} md={{ span: 5, offset: 4 }} xl={{ span: 2, offset: 13 }}>
          <Button
            type="primary"
            shape="round"
            onClick={onRefresh}
            icon={<SyncOutlined width="100" />}
            loading={isLoading}
          >
            Refresh
          </Button>
        </Col>
        <Col xs={24} md={{ span: 5 }} xl={{ span: 2 }}>
          <Button
            type="primary"
            shape="round"
            onClick={onExport}
            icon={<DownloadOutlined width="100" />}
            loading={loadingExport}
          >
            Export
          </Button>
        </Col>
      </Row>
      <Row>
        <Table
          style={{ width: "100%" }}
          scroll={{ x: 2000 }}
          onChange={handleTableChange}
          loading={isLoading}
          pagination={{
            ...currentPagination,
            showSizeChanger: total > 10,
            total,
            pageSizeOptions: [10, 20, 50, 100],
          }}
          dataSource={tableData}
          columns={columns}
          showSorterTooltip
        />
      </Row>
    </React.Fragment>
  );
};

export default MainView;
